/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import * as React from 'react'
import { ModalProvider } from './src/context/ModalContext'
import { ProfileProvider } from './src/context/ProfileContext'

export const wrapRootElement = ({ element }) => (
  <ModalProvider>
    <ProfileProvider>{element}</ProfileProvider>
  </ModalProvider>
)
