import React from "react"

const defaultState = {}

const ModalContext = React.createContext(defaultState)

const ModalProvider = props => {
	const { children } = props
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const onToggle = e => setIsModalOpen(!isModalOpen)
  const onClickClose = () => setIsModalOpen(false)
  const modal = { isModalOpen, setIsModalOpen, onToggle, onClickClose }

	return (
		<ModalContext.Provider
			value={modal}
		>
			{children}
		</ModalContext.Provider>
	)
}

export default ModalContext
export { ModalProvider }