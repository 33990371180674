import React from 'react'

const defaultState = {}

const ProfileContext = React.createContext(defaultState)

const ProfileProvider = props => {
  const { children } = props
  const [showProfileModal, setShowProfileModal] = React.useState(false)
  const [profile, setProfile] = React.useState({ image: '', name: '', _rawBio: '' })

  return (
    <ProfileContext.Provider value={{ profile, setProfile, showProfileModal, setShowProfileModal }}>{children}</ProfileContext.Provider>
  )
}

export default ProfileContext
export { ProfileProvider }
